import '../Collaborator.css'
import { useEffect, useState, useContext } from "react";
import CollaboratorContext from "../../../context/CollaboratorContext/CollaboratorContext";
import { Form, Formik } from 'formik';
import { Dialog, DialogContent, TextField  } from "@material-ui/core"
import { useMutation } from '@apollo/client';
import { CREATE_SALARYHISTORY, GET_USERS_BY_ID, GET_ALL_USER_EVENTUAL, GET_ALL_USERS_COLLABORATOR, GET_EXCEL_ACTIVE_USER, UPDATE_USERS, GET_TIMELINE_EVENTS, GET_USER_HOLIDAYS } from '../../../Querys/querys';
import {clearSalaryHistory} from '../../../context/CollaboratorContext/Actions'
import InputAdornment from "@mui/material/InputAdornment";
import { formatterMxn } from '../../../helpers/formatoMoneda';
import moment from 'moment';
import { AdminContext } from '../../../context/AdminContext/AdminContext';
import { useHistory } from 'react-router-dom';
import { SuccessfulAlert } from '../../../alerts/successAlerts';
import { handleSetActiveStep } from '../../../helpers/Collaborator/Collaborator';
import { postMeilCandidate } from '../../../services/candidateService';

export const SalaryHistory = () => {
    
    const { state, dispatch } = useContext(CollaboratorContext);
    const { adminState } = useContext(AdminContext);
    const history:any = useHistory();
    const [updateData] = useMutation(CREATE_SALARYHISTORY);
    const [updateColaboradores] = useMutation(UPDATE_USERS, {
        refetchQueries: [
          {
            query: GET_USERS_BY_ID,
            variables: { getUsersId: state.collaborator.id },
          },
          {
            query: GET_ALL_USER_EVENTUAL,
          },
          { query: GET_ALL_USERS_COLLABORATOR },
          { query: GET_EXCEL_ACTIVE_USER },
          {
            query: GET_TIMELINE_EVENTS,
            variables: { id: state.collaborator.id },
          },
          {
            query: GET_USER_HOLIDAYS,
            variables: { id: state.collaborator.id },
          }
        ],
      });
    const [grossSalary, setGrossSalary] = useState("");
    const [showGrossSalary, setShowGrossSalary] = useState(false);
    const [DS, setDS] = useState("");
    const [showDS, setShowDS] = useState(false);
    const [recordableSalary, setRecordableSalary] = useState("");
    const [showRecordableSalary, setShowRecordableSalary] = useState(false);
    const [SDG, setSDG] = useState("");
    const [showSDG, setShowSDG] = useState(false);
    const [ISD, setISD] = useState("");
    const [showISD, setShowISD] = useState(false);
    const [notRecordableSalary, setNotRecordableSalary] = useState("0");
    const [tipoEsquema, setTipoEsquema] = useState("");
    const [activo, setactivo] = useState(false);
    const [fromDataHistory, setFromDataHistory] = useState<any>({});

    const handleClose = ()=>{
        clearSalaryHistory(dispatch);
        setactivo(false);
        setGrossSalary("");
        setShowGrossSalary(false);
        setDS("");
        setShowDS(false);
        setRecordableSalary("");
        setShowRecordableSalary(false);
        setSDG("");
        setShowSDG(false);
        setISD("");
        setShowISD(false);
        setTipoEsquema("");
        setFromDataHistory({});
    }

    const handleSetFormat = (value: any, field: any) => {
        
        if (typeof value === "string") {

          let valueFormatted = value.replace(/,/g, "");
    
          valueFormatted = parseFloat(valueFormatted).toFixed(2);
    
          let x = formatterMxn(valueFormatted);
          if (x === "nan") return false;
          x = x.substring(1);
          updateTypeSalary(field, x);
        } else {
          let x = formatterMxn(value);
          if (x === "nan") return false;
          x = x.substring(1);
          updateTypeSalary(field, x);
        }
    };

    const handleSetNotRecordableSalary = async (itm = "0.00", itg = "0.00") => {
        
        if (itm && itg) {
          let value = await handleCleanFormat(itm);
          let value2 = await handleCleanFormat(itg);
          let valueRes = value - value2;
          await handleSetFormat(valueRes, "notRecordableSalary");
        } else {
          setNotRecordableSalary("0.00");
          document.getElementById("notRecordableSalary")?.click();
        }
    };


    useEffect(() => {
    if(state.tipoEsquemaHistory=== "Mixto"){
        if(state.addSalaryHistory){
            setGrossSalary(state.grossSalaryHistory);
            if(handleCleanFormat(state.grossSalaryHistory) !== state.collaborator.grossSalary){
                setShowGrossSalary(true);
            }
            setDS(state.DSHistory);
            if(handleCleanFormat(state.DSHistory) !== state.collaborator.DS){
                setShowDS(true);
            }
            setRecordableSalary(state.recordableSalaryHistory);
            if(handleCleanFormat(state.recordableSalaryHistory) !== state.collaborator.recordableSalary){
                setShowRecordableSalary(true);
            }
            setSDG(state.SDGHistory);
            if(handleCleanFormat(state.SDGHistory) !== state.collaborator.SDG){
                setShowSDG(true);
            }
            setISD(state.ISDHistory);
            if(handleCleanFormat(state.ISDHistory) !== state.collaborator.ISD){
                setShowISD(true);
            }
            setTipoEsquema(state.tipoEsquemaHistory);
            setFromDataHistory(state.formDataHistory);

            handleSetNotRecordableSalary(
                state.grossSalaryHistory,
                state.recordableSalaryHistory
              );
        }
      }
      if(state.tipoEsquemaHistory=== "Nomina"){
        if(state.addSalaryHistory){
            setGrossSalary(state.grossSalaryHistory);
            if(handleCleanFormat(state.grossSalaryHistory) !== state.collaborator.grossSalary){
                setShowGrossSalary(true);
            }
            setDS(state.DSHistory);
            if(handleCleanFormat(state.DSHistory) !== state.collaborator.DS){
                setShowDS(true);
            }
            
            setISD(state.ISDHistory);
            if(handleCleanFormat(state.ISDHistory) !== state.collaborator.ISD){
                setShowISD(true);
            }
            setTipoEsquema(state.tipoEsquemaHistory);
            setFromDataHistory(state.formDataHistory);

            handleSetNotRecordableSalary(
                state.grossSalaryHistory,
                state.recordableSalaryHistory
              );
        }

        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const handleCleanFormat = (value: string | number | null | undefined): number => {

        if (value == null) {
            throw new Error("El valor proporcionado es nulo o indefinido.");
        }
        value = String(value);
    
        console.log("Valor recibido:", value);
        // Eliminar ".00" si está presente
        if (value.includes(".00")) {
            value = value.substring(0, value.indexOf(".00"));
        }
        // Eliminar comas y otros caracteres no numéricos
        value = value.replace(/,/g, "");
        // Validar si el valor es numérico después de limpiar
        if (isNaN(Number(value))) {
            throw new Error("El valor proporcionado no es numérico.");
        }    
        // Convertir a número con dos decimales
        let x = parseFloat(value).toFixed(2);
        let y = parseFloat(x);    
        return y;
    };

    const handleSetFormat2 = (value: any) => {
        //parse value to string if is number
        if (typeof value === "number") {
          value = value.toString();
        }
    
        if (typeof value === "string") {
          let valueFormatted = value.replace(/,/g, "");
    
          valueFormatted = parseFloat(valueFormatted).toFixed(2);
    
          let x = formatterMxn(valueFormatted);
          if (x === "nan") return false;
          let y = x.substring(1);
          return y;
          // formik.setFieldValue(field, x);
        }
      };


    const initialValues = () => {
        
        return {
            grossSalary:grossSalary,
            DS:state.DSHistory,
            recordableSalary:state.recordableSalaryHistory,
            SDG:state.SDGHistory,
            ISD:state.ISDHistory,
            dateEditSalary:""
        }
    }

    const handleSetDS = async (e: any) => {
    
        //if e is empty, set DS to 0
        if (e === "") return false;
    
        let value = await handleCleanFormat(e);
        value = value / 30;
        const valueRes = await handleSetFormat2(value);
        if (valueRes === false) {
            setDS("0.00");
        } else {    
            setDS(String(valueRes));
        }
        return valueRes;
    };

    const updateTypeSalary = (field: any, salary: any) => {
        if(field === "grossSalary"){
            setGrossSalary(salary);
            
        }
        if(field === "recordableSalary"){
            setRecordableSalary(salary);

        }
        if(field === "notRecordableSalary"){
            setNotRecordableSalary(salary)
        }
        if(field === "DS"){
            setDS(salary)
        }
        if(field === "SDG"){
            setSDG(salary)
        }
        if(field === "ISD"){
            setISD(salary);
        }
        if(field === "notRecordableSalary"){
            setNotRecordableSalary(salary);
        }
    }

    const activateForm = (e: any) => {
        if (e.target.value === "CONFIRMAR") {
          setactivo(true);
        } else {
          setactivo(false);
        }
    };

    return (
        <div>
            <Formik
            initialValues={initialValues()}
            onSubmit={formData => {

                if(grossSalary !== "" && DS !== "" && recordableSalary !== "" && SDG !== "" && ISD !== "" && formData.dateEditSalary !== ""){
                    const data: any = fromDataHistory
                    data.grossSalary = handleCleanFormat(grossSalary)
                    data.DS = handleCleanFormat(DS)
                    data.recordableSalary = handleCleanFormat(recordableSalary)
                    data.SDG = handleCleanFormat(SDG)
                    data.ISD = handleCleanFormat(ISD)
                    data.notRecordableSalary = handleCleanFormat(notRecordableSalary)
                    updateData({
                        variables:{
                            input: {
                                id_Collaborator:  parseInt(state.collaborator.id),  
                                grossSalary: handleCleanFormat(grossSalary),
                                DS: handleCleanFormat(DS),
                                recordableSalary: handleCleanFormat(recordableSalary),
                                SDG: handleCleanFormat(SDG),
                                ISD: handleCleanFormat(ISD),
                                user: adminState?.Usuario,
                                dateAdd: moment(formData.dateEditSalary).toISOString(),
                                

                            },
                        },
                    }).then(()=>{

                        updateColaboradores({
                        variables: {
                            updateUsersId: state.collaborator?.id,
                            input: data,
                            process: {
                            tab: "information",
                            section: "section_3",
                            },
                        },
                        })
                        .finally(() => {
                        if (data?.own_register) {
                            handleClose();
                            history.push(`/collaborators/${state.collaborator?.id}/2`, history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
                            SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                            handleSetActiveStep(0, dispatch);
                            });
                        } else if (
                            !data?.own_register &&
                            !state.collaborator?.register_process[0]?.completed
                        ) {
                            
                            postMeilCandidate(
                            state.collaborator.email,
                            state.collaborator.token
                            ).then(() => {
                                handleClose();
                                history.push(`/collaborators/id/0`);
                                });
                                SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                                handleSetActiveStep(0, dispatch);
                            });
                        } else {
                            SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                                handleClose();
                                history.push(`2`,history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
                                handleSetActiveStep(0, dispatch);
                            });
                        }
                        });




                        
                    });
                    
                }
                if(grossSalary !== ""  && ISD !== "" && formData.dateEditSalary !== ""){
                    const data: any = fromDataHistory
                    data.grossSalary = handleCleanFormat(grossSalary)
                    data.DS = handleCleanFormat(DS)
                    data.recordableSalary = handleCleanFormat(recordableSalary)
                    data.SDG = handleCleanFormat(SDG)
                    data.ISD = handleCleanFormat(ISD)
                    data.notRecordableSalary = handleCleanFormat(notRecordableSalary)
                    updateData({
                        variables:{
                            input: {
                                id_Collaborator:  parseInt(state.collaborator.id),  
                                grossSalary: handleCleanFormat(grossSalary),
                                DS: handleCleanFormat(DS),
                                recordableSalary: handleCleanFormat(recordableSalary),
                                SDG: handleCleanFormat(SDG),
                                ISD: handleCleanFormat(ISD),
                                user: adminState?.Usuario,
                                dateAdd: moment(formData.dateEditSalary).toISOString(),
                                

                            },
                        },
                    }).then(()=>{

                        updateColaboradores({
                        variables: {
                            updateUsersId: state.collaborator?.id,
                            input: data,
                            process: {
                            tab: "information",
                            section: "section_3",
                            },
                        },
                        })
                        .finally(() => {
                        if (data?.own_register) {
                            handleClose();
                            history.push(`/collaborators/${state.collaborator?.id}/2`, history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
                            SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                            handleSetActiveStep(0, dispatch);
                            });
                        } else if (
                            !data?.own_register &&
                            !state.collaborator?.register_process[0]?.completed
                        ) {
                            
                            postMeilCandidate(
                            state.collaborator.email,
                            state.collaborator.token
                            ).then(() => {
                                handleClose();
                                history.push(`/collaborators/id/0`);
                                });
                                SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                                handleSetActiveStep(0, dispatch);
                            });
                        } else {
                            SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
                                handleClose();
                                history.push(`2`,history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
                                handleSetActiveStep(0, dispatch);
                            });
                        }
                        });




                        
                    });
                    
                }
                
            }}
            >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
            }) => (
                <Dialog open={state.addSalaryHistory} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
                    <div className="dialogContainer">
                        <div className="containerTitle">
                            <div className="centerContainer"
                            style={{
                                marginLeft:'auto',
                                marginRight:'auto'
                            }}
                            >
                                <h2 id="form-dialog-title" data-testid="TitleModal">¿Está seguro de que deseas cambiar la siguiente información de ingresos?</h2>
                                    
                            </div>
                            <div className="contenedorCerrarModal">
                                <div className="iconoCerrarModal" onClick={(()=>handleClose())}></div>      
                            </div> 
                        </div>
                        <DialogContent>

                            <Form onSubmit={handleSubmit}>
                                {
                                    showGrossSalary && (
                                        <TextField
                                            fullWidth
                                            className="dialogFields"
                                            name="grossSalary"
                                            label="Ingreso mensual"
                                            variant="outlined"
                                            value={grossSalary}
                                            onChange={
                                                (e)=>{
                                                    setGrossSalary(e.target.value)
                                                    handleSetDS(e.target.value);
                                                }
                                            }
                                            error={grossSalary === "" ? true : false}
                                            helperText={
                                                grossSalary === "" ? true : false && "el campo es requerido"
                                            }
                                            size="small" 
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            onKeyPress={(event: any) => {
                                                if (!/^[0-9.]+$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }}
                                            onBlur={() =>
                                                handleSetFormat(grossSalary, "grossSalary")
                                            }

                                        />
                                    )
                                }
                                {
                                    showDS && (
                                        <TextField
                                            fullWidth
                                            className="dialogFields"
                                            name="DS"
                                            label="Salario diario"
                                            variant="outlined"
                                            value={DS}
                                            onChange={
                                                (e)=>{
                                                    setDS(e.target.value)
                                                }
                                            }
                                            error={DS === "" ? true : false}
                                            helperText={
                                                DS === "" ? true : false && "el campo es requerido"
                                            }
                                            size="small" 
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            onKeyPress={(event: any) => {
                                                if (!/^[0-9.]+$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }}
                                            onBlur={() =>
                                                handleSetFormat(DS, "DS")
                                            }
                                            
                                        />
                                    )
                                }
                                
                                
                                {tipoEsquema === "Mixto" && (
                                    <div>
                                        {
                                            showRecordableSalary && (
                                                <TextField
                                                    fullWidth
                                                    className="dialogFields"
                                                    name="recordableSalary"
                                                    label="Ingreso mensual gravable"
                                                    variant="outlined"
                                                    value={recordableSalary}
                                                    onChange={
                                                        (e)=>{
                                                            setRecordableSalary(e.target.value)
                                                            handleSetNotRecordableSalary(
                                                                grossSalary,
                                                                e.target.value
                                                            );
                                                        }
                                                    }
                                                    error={recordableSalary === "" ? true : false}
                                                    helperText={
                                                        recordableSalary === "" ? true : false && "el campo es requerido"
                                                    }
                                                    size="small" 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                    onKeyPress={(event: any) => {
                                                        if (!/^[0-9.]+$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    onBlur={() =>
                                                        handleSetFormat(recordableSalary, "recordableSalary")
                                                    }

                                                />
                                            )
                                        }
                                    
                                        {
                                            showSDG && (
                                                <TextField
                                                    fullWidth
                                                    className="dialogFields"
                                                    name="SDG"
                                                    label="Salario diario gravable"
                                                    variant="outlined"
                                                    value={SDG}
                                                    onChange={
                                                        (e)=>{
                                                            setSDG(e.target.value)
                                                        }
                                                    }
                                                    error={SDG === "" ? true : false}
                                                    helperText={
                                                        SDG === "" ? true : false && "el campo es requerido"
                                                    }
                                                    size="small" 
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                    onKeyPress={(event: any) => {
                                                        if (!/^[0-9.]+$/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    onBlur={() =>
                                                        handleSetFormat(SDG, "SDG")
                                                    }
                                                />
                                            )
                                        }
                                    </div>
                                )}
                                <div>
                                    {
                                        showISD && (
                                            <TextField
                                                fullWidth
                                                className="dialogFieldsSDI"
                                                name="ISD"
                                                label="Salario diario integrado"
                                                variant="outlined"
                                                value={ISD}
                                                onChange={
                                                    (e)=>{
                                                        setISD(e.target.value)
                                                    }
                                                }
                                                error={ISD === "" || handleCleanFormat(ISD) < handleCleanFormat(SDG) ? true : false}
                                                helperText={
                                                    ISD === "" ? true : false && "el campo es requerido"
                                                }
                                                size="small" 
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">$</InputAdornment>
                                                    ),
                                                }}
                                                onKeyPress={(event: any) => {
                                                    if (!/^[0-9.]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                }}
                                                onBlur={() =>
                                                    handleSetFormat(ISD, "ISD")
                                                }
                                            />
                                        )
                                    }
                                    {
                                        handleCleanFormat(ISD) < handleCleanFormat(SDG) ? (
                                            <p
                                            className="dialogFieldsErrorAlert"
                                            >
                                            El valor del salario diario integrado no puede ser menor que el salario diario gravable
                                            </p>
                                        ): null
                                    }
                                    
                                </div>
                                <div
                                className='dateEditSalaryContainer'
                                >
                                    <div
                                   className='dateEditSalaryText'
                                    >
                                        <span>
                                        Fecha de aplicación
                                        </span>
                                    </div>
                                    <TextField
                                        type="date"
                                        defaultValue={moment().format("YYYY-MM-DD")}
                                        autoFocus={true}
                                        name="dateEditSalary"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        error={Boolean(errors.dateEditSalary)}
                                        onBlur={(e) => handleChange(e)}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                    />
                                </div>
                                <div className="em_mensajePrtincipal">
                                    <span >
                                    Escribe CONFIRMAR para confirmar
                                    </span>
                                </div>
                                <div
                                className='dateEditSalaryContainer'
                                >
                                    <input
                                    className="inputConfirm"
                                    type="text"
                                    onChange={(e) => activateForm(e)}
                                    />
                                </div>
                                <div className="centerContainer">
                                    <button className="buttonCancelCalendar" onClick={() => handleClose()}>
                                        <b className="buttonCancelCalendarText"
                                        style={{
                                            textTransform: "capitalize"
                                        }}
                                        >Cancelar</b>
                                    </button>
                                    <div className="conteinerSave">
                                        {activo === true ? (
                                            
                                            <button type="submit" className="buttonSaveCalendar">
                                            <b className="buttonSaveCalendarText"
                                            style={{
                                                textTransform: "capitalize"
                                            }}
                                            >Guardar
                                            </b>
                                            </button>
                                        ):
                                        (
                                            <button className="buttonSaveCalendarInactive"
                                            onClick={
                                                (e)=>{
                                                    e.preventDefault()
                                                }
                                            }
                                            >
                                            <b className="buttonSaveCalendarText"
                                            style={{
                                                textTransform: "capitalize"
                                            }}
                                            >Guardar
                                            </b>
                                            </button>
                                        )}
                                        
                                        
                                    </div>
                                </div>
                            </Form>

                        </DialogContent>
                    </div>
                </Dialog>
            )}
        </Formik>
      </div>
    )
}
